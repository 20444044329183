import "twin.macro"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const About = () => {
  return (
    <Layout>
      <Seo
        title="About Us"
        description=" <strong>Career therapies</strong>&nbsp;provides you with the right
            knowledge, resources and expert advice that you need to land your
            dream job. Over the past years, we have helped many job seekers
            build their&nbsp;
            <strong>Resumes&nbsp;and LinkedIn profiles stronger</strong>, find
            the ideal career path for them with our experts, gain success in
            their career with fluent English skills, mastering the art of
            interview and boost their chances of getting their dream job.&nbsp;"
      />

      <div tw="min-height[3vh] md:min-height[5vh]"></div>
      <div tw="prose prose-sm sm:prose lg:prose-lg xl:prose-xl mx-auto prose-yellow p-5">
        <h1 tw="text-center">About us</h1>
        <p>
          <p>
            <strong>Career therapies</strong>&nbsp;provides you with the right
            knowledge, resources and expert advice that you need to land your
            dream job. Over the past years, we have helped many job seekers
            build their&nbsp;
            <strong>Resumes&nbsp;and LinkedIn profiles stronger</strong>, find
            the ideal career path for them with our experts, gain success in
            their career with fluent English skills, mastering the art of
            interview and boost their chances of getting their dream job.&nbsp;
          </p>
          <p>&nbsp;</p>
          <blockquote>
            We are a one-stop destination for all of your career related
            problems.&nbsp;
          </blockquote>
          <p>&nbsp;</p>
          <p>
            We will help you in every step of your career. From building the
            right resume to cracking the interview of your dreams and finding
            your ideal job.&nbsp;
          </p>
          <p>&nbsp;</p>
          <blockquote>
            Your Career is one of the Most important decision of your life
          </blockquote>
          <p>&nbsp;</p>
          <p>
            Career therapist will help you throughout the journey to get the
            best out of yourself and marketing you as the most eligible person
            for your dream job.&nbsp;
          </p>
          <p>&nbsp;</p>
          <blockquote>
            Most important decision of your life is your career
          </blockquote>
          <p>&nbsp;</p>
          <p>
            Our only goal is to help you discover your right career path and
            helping you throughout your career journey. We have made it easy for
            job seekers to build professional resumes and LinkedIn profiles
            specifically tailored and personalized as per your interests and
            goals.
          </p>
          <p>&nbsp;</p>
        </p>
      </div>
    </Layout>
  )
}

export default About
